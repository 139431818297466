import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";
import { useContext } from "react";
import userImage from "../../../images/Headerimages/blur-slide.jpg";
import { useLogoutMutation } from "../../../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { AiFillMessage } from "react-icons/ai";
import { IoIosNotificationsOutline } from "react-icons/io";

function Topbar() {
  let userRole = localStorage.getItem("userRole");
  let userDetails = localStorage.getItem("userDetails");
  let userAccessToken = localStorage.getItem("userAccessToken");
  let accesstoken = localStorage.getItem("accesstoken");
  let medusatoken = localStorage.getItem("medusatoken");
  const NStoken = JSON.parse(localStorage.getItem("NStoken"));

  const cleanedUserRole = userRole.replace(/["']/g, "");
  const navigate = useNavigate();
  const { changeStyle, changeStyle1 } = useContext(Auth);
  const [adminLogout, responseInfo] = useLogoutMutation();
  const handleLogout = (e) => {
    e.preventDefault();
    adminLogout();
    // localStorage.removeItem("userToken");
    // localStorage.removeItem("userAccessToken");
    // localStorage.removeItem("userDetails");
    // localStorage.removeItem("accesstoken");
    // localStorage.removeItem("medusatoken");
    // localStorage.removeItem("NStoken");
    localStorage.clear();
    if (cleanedUserRole == "comic_agent") {
      navigate("/agent-login");
    } else {
      navigate("/");
    }
  };
  return (
    <div>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        {/*  <!-- Sidebar Toggle (Topbar) --> */}
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
          onClick={changeStyle1}
        >
          <i className="fa fa-bars"></i>
        </button>
        {/*  <!-- Topbar Search --> */}
        <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <div className="menu-icon">
              <RxHamburgerMenu onClick={changeStyle} />
            </div>
          </div>
        </form>
        {/*  <!-- Topbar Navbar --> */}
        <ul className="navbar-nav ml-auto">
          {/*  <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
          <li className="nav-item dropdown no-arrow d-sm-none">
            <a
              href="#"
              className="nav-link dropdown-toggle"
              id="searchDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-search fa-fw"></i>
            </a>
            {/*   <!-- Dropdown - Messages --> */}
            <div
              className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <BsSearch style={{ color: "white" }} />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>

          {/*  <!-- Nav Item - Alerts --> */}
          <li className="nav-item dropdown no-arrow mx-1">
            {/* <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <AiFillMessage className='h4'/>
                           
                            <span className="badge badge-danger badge-counter">3+</span>
                        </a> */}
            {/*   <!-- Dropdown - Alerts --> */}
            {/* <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="alertsDropdown">
                            <h6 className="dropdown-header">
                                Alerts Center
                            </h6>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fas fa-file-alt text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 12, 2019</div>
                                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-success">
                                        <i className="fas fa-donate text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 7, 2019</div>
                                    $290.29 has been deposited into your account!
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-warning">
                                        <i className="fas fa-exclamation-triangle text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 2, 2019</div>
                                    Spending Alert: We've noticed unusually high spending for your account.
                                </div>
                            </a>
                            <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                        </div> */}
          </li>

          {/*  <!-- Nav Item - Messages --> */}
          {/* <li className="nav-item dropdown no-arrow mx-1">
                        <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                             <IoIosNotificationsOutline className='h4'/>
                            <span className="badge badge-danger badge-counter">7</span>
                        </a>

                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="messagesDropdown">
                            <h6 className="dropdown-header">
                                Message Center
                            </h6>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="img/undraw_profile_1.svg"
                                        alt="..." />
                                    <div className="status-indicator bg-success"></div>
                                </div>
                                <div className="font-weight-bold">
                                    <div className="text-truncate">Hi there! I am wondering if you can help me with a
                                        problem I've been having.</div>
                                    <div className="small text-gray-500">Emily Fowler · 58m</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="img/undraw_profile_2.svg"
                                        alt="..." />
                                    <div className="status-indicator"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">I have the photos that you ordered last month, how
                                        would you like them sent to you?</div>
                                    <div className="small text-gray-500">Jae Chun · 1d</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="img/undraw_profile_3.svg"
                                        alt="..." />
                                    <div className="status-indicator bg-warning"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">Last month's report looks great, I am vry happy with
                                        the progress so far, keep up the good work!</div>
                                    <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                        alt="..." />
                                    <div className="status-indicator bg-success"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                                        told me that people say this to all dogs, even if they aren't good...</div>
                                    <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                </div>
                            </a>
                            <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                        </div>
                    </li> */}

          <div className="topbar-divider d-none d-sm-block"></div>

          {/* <!-- Nav Item - User Information --> */}
          <li className="nav-item dropdown no-arrow mr-4">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <b className="mr-2 d-none d-lg-inline text-black">
                {cleanedUserRole}
              </b>
              <img className="img-profile rounded-circle" src={userImage} />
            </a>
            {/*  <!-- Dropdown - User Information --> */}
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              {/* <a className="dropdown-item" href="#">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Profile
                            </a>
                            <a className="dropdown-item" href="#">
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Settings
                            </a> */}
              {/* <a  onClick={() => { navigate('/Changepassword') }} className="dropdown-item" href="/changepassword">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Change Password
                            </a> */}
              <a
                className="dropdown-item"
                onClick={() => {
                  navigate("/changepassword");
                }}
              >
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Change Password
              </a>
              {/* <a className="dropdown-item" href="#">
                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                Activity Log
                            </a> */}
              <div className="dropdown-divider"></div>
              <button
                onClick={(e) => {
                  handleLogout(e);
                }}
                className="dropdown-item"
                data-toggle="modal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </button>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Topbar;
