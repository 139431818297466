import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
export const Auth = createContext();
function Authcontext({ children }) {
  const userDetail = JSON.parse(localStorage.getItem("userInfo"));
  const BASE_URL = `${process.env.REACT_APP_BASE_URL}api/`;
  // const BASE_URL = "https://www.bailkolhu.com/api/"
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );
  const [loader, setLoader] = useState(false);
  const Swal = require("sweetalert2");
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [updateModelShow, setUpdateModelShow] = useState(false);
  const [englishModelShow, setEnglishModelShow] = useState(false);
  //state for save only in global model
  const [addModelShow, setAddModelShow] = useState(false);
  const [updateEnglishModelShow, setUpdateEnglishModelShow] = useState(false);
  const [updateHindiModelShow, setUpdateHindiModelShow] = useState(false);
  const [images, setImages] = useState({
    mediaImages: [],
  });

  //state for sending images of each model
  const [mediaImage, setMediaImage] = useState({
    sliderWebImage: "",
    sliderMobileImage: "",
  });
  const [inputFields, setInputFields] = useState([
    {
      mainHeading: "",
      subHeading: "",
      description: "",
    },
  ]);

  const [updateTestimonial, setUpdateTestimonial] = useState({});

  const [brand, setBrand] = useState({
    clickUrl: "",
    imageWeb: "",
    imageMobile: "",
    imageAlt: "",
  });

  const [updateBrand, setUpdateBrand] = useState({
    newclickurl: "",
    newimageweb: "",
    newimagemobile: "",
    newalt: "",
  });

  const [header, setHeader] = useState({
    email: "",
    phoneNumber: "",
    logoalt: "",
    language: "",
    iconUrl: "",
    buttonText: "",
  });

  const [brandAbout, setBrandAbout] = useState({
    heading: "",
    subHeading: "",
    description: "",
    imageurl: "",
  });

  const [brandProduct, setBrandProduct] = useState({
    productName: "",
    heading: "",
    subHeading: "",
    description: "",
    productImage: "",
    nourishStoreLink: "",
    amazonStoreLink: "",
  });

  const [brandPhilosophy, setBrandPhilosphy] = useState({
    brandPhilosophyImage: "",
    brandPhilosophyMobileImage: "",
  });

  const [brandAboutOil, setBrandAboutOil] = useState({
    heading: "",
    description: "",
    imageUrl: "",
    lang: "",
  });

  const [brandMedia, setBrandMedia] = useState({
    title: "",
    heading: "",
    description: "",
    sourceUrl: "",
    source: "",
    image: "",
  });

  const [brandStory, setBrandStory] = useState({
    heading: "",
    subHeading: "",
    buttonText: "",
    imageUrl: "",
    backgroundImageUrl: "",
  });

  const [blog, setBlog] = useState({
    blogTitle: "",
    imageUrl: "",
    author: "",
    specialText: "",
    blogInnerContent: "",
    tags: "",
    slug: "",
      title: "",
      description: "",
      ogTitle: "",
      ogType: "",
      ogDescription: "",
      ogUrl: "",
      ogImageUrl: "",
      canonical: "",
      keyword: "",
      twitterCard: "",
      twitterTitle: "",
      twitterType: "",
      twitterDescription: "",
      twitterUrl: "",
      ogLocal: "",
      twitterLocal: "",
      siteName: "",
      LinkedInTitle: "",
      LinkedInImageUrl: "",
      LinkedInDescription: "",
      ogImage: "",
   
  });

  const [tvc, setTvc] = useState({
    videoUrl: "",
    backgroundImageUrl: "",
  });

  const [contact, setContact] = useState({
    facebook: "",
    twitter: "",
    instragram: "",
    youtube: "",
    linkedin: "",
    wikipedia: "",
  });

  const [contactPage, setContactPage] = useState({
    mainHeading: "",
    subHeading: "",
    industryName: "",
    cinNumber: "",
    address: "",
    landlineNumber: "",
    mobileNumber: "",
    firstEmail: "",
    secondEmail: "",
    enterContactPersonName: "",
  });

  const [createCategoriesComic, setCreateCategoriesComic] = useState({
    lang: "",
    comicName: {
      forHindi: "",
      forEnglish: "",
    },
    comicSlug: "",
    mainImage: "",
    backgroundImage: "",
    siderImages: "",
    mobileSliderImage: "",

    aboutMainHeading: {
      forHindi: "",
      forEnglish: "",
    },
    aboutsubHeading: {
      forHindi: "",
      forEnglish: "",
    },
    aboutDescription: {
      forHindi: "",
      forEnglish: "",
    },
    
    buttonText: {
      forHindi: "",
      forEnglish: "",
    },
    rightImage: "",
    leftSliderImage: "",
    buttonLink: "",
    storyMainHeading: {
      forHindi: "",
      forEnglish: "",
    },
    storySubHeading: {
      forHindi: "",
      forEnglish: "",
    },
    storyDescription: {
      forHindi: "",
      forEnglish: "",
    },
    storyRightImage: "",
    storyLeftImage: "",
    storyButtonText: {
      forHindi: "",
      forEnglish: "",
    },
    buttonStoryLink: "",
    campaignHeading: "",
    listing: [],
  });

  const [editorState, setEditorState] = useState({
    editorHtml: "",
  });
   const [globalEditor, setGlobalEditor] = useState({
     editorHtml: "",
   });

  const [getSlider, setGetSlider] = useState([]);

  const handleError = (error, file) => {
  };

  const changeStyle = () => {
    if (
      style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };

  const changeStyle1 = () => {
    if (
      style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };

  const Order=()=>{
    
  }

  return (
    <>
      <Auth.Provider
        value={{
          inputFields,
          setInputFields,
          modalShow,
          setModalShow,
          handleError,
          changeStyle,
          changeStyle1,
          style,
          state,
          setState,
          loader,
          setLoader,
          userDetail,
          brand,
          setBrand,
          getSlider,
          updateBrand,
          setUpdateBrand,
          Swal,
          header,
          setHeader,
          updateModelShow,
          setUpdateModelShow,
          mediaImage,
          setMediaImage,
          images,
          setImages,
          englishModelShow,
          setEnglishModelShow,
          editorState,
          setEditorState,
          brandAbout,
          setBrandAbout,
          brandProduct,
          setBrandProduct,
          brandPhilosophy,
          setBrandPhilosphy,
          brandAboutOil,
          setBrandAboutOil,
          addModelShow,
          setAddModelShow,
          updateEnglishModelShow,
          setUpdateEnglishModelShow,
          updateHindiModelShow,
          setUpdateHindiModelShow,
          brandMedia,
          setBrandMedia,
          brandStory,
          setBrandStory,
          blog,
          setBlog,
          tvc,
          setTvc,
          contact,
          setContact,
          contactPage,
          setContactPage,
          createCategoriesComic,
          setCreateCategoriesComic,
          updateTestimonial,
          setUpdateTestimonial,
          globalEditor,
          setGlobalEditor,
        }}
      >
        {children}
      </Auth.Provider>
    </>
  );
}

export default Authcontext;
