import React from "react";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillFolderOpen, AiFillDashboard } from "react-icons/ai";
import "./slider.css";
function Slider() {
  const userDetail = JSON.parse(localStorage.getItem("userDetails"));
  // console.log(userDetail);
  // let { storeManager: { user: { api_token, id } } } = userDetail
  const { style } = useContext(Auth);
  const navigate = useNavigate();
  let userRole = localStorage.getItem("userRole");
  const cleanedUserRole = userRole.replace(/["']/g, "");
  // console.log("userrole" + cleanedUserRole);

  return (
    <>
      <ul className={style} id="accordionSidebar">
        <a
          style={{ justifyContent: "space-between" }}
          className="sidebar-brand d-flex"
          href="#"
        >
          <div className="sidebar-brand-icon logo-upper">
            <img
              className="logo-img w-50 d-flex align-items-center mx-auto"
              src="https://brandconnect-media.s3.amazonaws.com/bailkolhu-media/website-images/prod/new logo_OYFtDfbo.png"
              alt="Brand logo"
            />
          </div>
        </a>
        {/* Role Base access  */}
        admin- {userRole}
        {cleanedUserRole === "NSadmin" ? (
          <>
            <li className="nav-item active">
              <a href="/nourish-dashboard" className="nav-link">
                <AiFillDashboard />
                <span style={{ marginLeft: "6px" }}>NS Dashboard</span>
              </a>
            </li>
            <li className="nav-item">
              <div>
                <a
                  className="nav-link collapsed"
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapseEcommerce"
                  aria-expanded="true"
                  aria-controls="collapseEcommerce"
                >
                  <AiFillFolderOpen />
                  <span style={{ marginLeft: "6px" }}>Ecommerce</span>
                </a>
                <div
                  id="collapseEcommerce"
                  className="collapse show"
                  aria-labelledby="headingPages"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Nourish Store :</h6>
                    <div className="dropdown">
                      <a
                        onClick={() => {
                          navigate("/nourish-order");
                        }}
                        className="collapse-item"
                      >
                        Nourish Orders
                      </a>
                      <a
                        onClick={() => {
                          navigate("/nourish-failed-order");
                        }}
                        className="collapse-item"
                      >
                        NS Failed Orders
                      </a>
                      <a
                        onClick={() => {
                          navigate("/nourish-userlist");
                        }}
                        className="collapse-item"
                      >
                        NS Reg. User Data
                      </a>
                      <a
                        onClick={() => {
                          navigate("/nourish-Voucher");
                        }}
                        className="collapse-item"
                      >
                        NS Voucher
                      </a>
                      <a
                        onClick={() => {
                          navigate("/nourish-customer-group");
                        }}
                        className="collapse-item"
                      >
                        NS Customer Group
                      </a>
                      {/* <a
                        onClick={() => {
                          navigate("/nourish-app-config");
                        }}
                        className="collapse-item"
                      >
                        NS App Config
                      </a> */}
                      {/* <a
      onClick={() => {
        navigate("/nourish-userlist");
      }}
      className="collapse-item"
    >
      Nourish Reg. Users
    </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </>
        ) : null}
        {cleanedUserRole === "editor" ? (
          <li className="nav-item active">
            <a href="/dashboard" className="nav-link">
              <AiFillDashboard />
              <span style={{ marginLeft: "6px" }}>SEO Dashboard</span>
            </a>
          </li>
        ) : cleanedUserRole === "admin" || cleanedUserRole === "Super_Admin" ? (
          <>
            <li className="nav-item active">
              <a href="/dashboard" className="nav-link">
                <AiFillDashboard />
                <span style={{ marginLeft: "6px" }}>Dashboard</span>
              </a>
            </li>
            <li className="nav-item active">
              <a href="/create-agent" className="nav-link">
                <span style={{ marginLeft: "6px" }}>Create Agent</span>
              </a>
            </li>
          </>
        ) : cleanedUserRole === "store_manager" ? (
          <li className="nav-item">
            <div>
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseEcommerce"
                aria-expanded="true"
                aria-controls="collapseEcommerce"
              >
                <AiFillFolderOpen />
                <span style={{ marginLeft: "6px" }}>Ecommerce</span>
              </a>
              <div
                id="collapseEcommerce"
                className="collapse"
                aria-labelledby="headingPages"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Bail Kolhu Ecommerce :</h6>
                  <div className="dropdown">
                    <a
                      onClick={() => {
                        navigate("/order");
                      }}
                      className="collapse-item"
                    >
                      BK Orders
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ) : null}
        {/* {userRole !== "store_manager" ? (
          <li className="nav-item">
            <div>
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseEcommerce"
                aria-expanded="true"
                aria-controls="collapseEcommerce"
              >
                <AiFillFolderOpen />
                <span style={{ marginLeft: "6px" }}>Ecommerce</span>
              </a>
              <div
                id="collapseEcommerce"
                className="collapse"
                aria-labelledby="headingPages"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Bail Kolhu Ecommerce :</h6>
                  <div className="dropdown">
                    <a
                      onClick={() => {
                        navigate("/order");
                      }}
                      className="collapse-item"
                    >
                     BK Orders
                    </a>
                     <a
                      onClick={() => {
                        navigate("/Pending-Order");
                      }}
                      className="collapse-item"
                    >
                     Bail Kolhu Failed Order                    </a> 
                    <a
                      onClick={() => {
                        navigate("/userlist");
                      }}
                      className="collapse-item"
                    >
                     BK Reg. Users
                    </a>
                    <a
                      onClick={() => {
                        navigate("/page-redirection");
                      }}
                      className="collapse-item"
                    >
                     BK Page Redirection
                    </a>
                  </div>
                  <h6 className="collapse-header">Nourish Ecommerce :</h6>
                  <div className="dropdown">
                    <a
                      onClick={() => {
                        navigate("/nourish-order");
                      }}
                      className="collapse-item"
                    >
                     Nourish Orders
                    </a>
                    <a
                      onClick={() => {
                        navigate("/nourish-userlist");
                      }}
                      className="collapse-item"
                    >
                     Nourish Reg. Users
                    </a>
                  </div> 
                </div>
              </div>
            </div>
          </li>
        ) : null } */}
        {/* Role Base access  */}
        <hr className="sidebar-divider my-0" />
        {/* Role Base access  */}
        {cleanedUserRole === "comic_agent" ? (
          <li className="nav-item active">
            <a href="/agentcomicwriter" className="nav-link">
              <AiFillDashboard />
              <span style={{ marginLeft: "6px" }}>Agent Dashboard</span>
            </a>
          </li>
        ) : cleanedUserRole === "admin" || cleanedUserRole === "editor" ? (
          <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Interface</div>
            <li className="nav-item">
              <div>
                <a
                  className="nav-link collapsed"
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapseEcommerce"
                  aria-expanded="true"
                  aria-controls="collapseEcommerce"
                >
                  <AiFillFolderOpen />
                  <span style={{ marginLeft: "6px" }}>Ecommerce</span>
                </a>
                <div
                  id="collapseEcommerce"
                  className="collapse"
                  aria-labelledby="headingPages"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Ecommerce Interface:</h6>
                    <div className="dropdown">
                      <a
                        onClick={() => {
                          navigate("/order");
                        }}
                        className="collapse-item"
                      >
                        Orders
                      </a>
                      {/* <a
                      onClick={() => {
                        navigate("/Pending-Order");
                      }}
                      className="collapse-item"
                    >
                     Bail Kolhu Failed Order                    </a>  */}
                      <a
                        onClick={() => {
                          navigate("/userlist");
                        }}
                        className="collapse-item"
                      >
                        BK Reg. Users
                      </a>
                      <a
                        onClick={() => {
                          navigate("/page-redirection");
                        }}
                        className="collapse-item"
                      >
                        BK Page Redirection
                      </a>
                    </div>
                    {/* <h6 className="collapse-header">Nourish Ecommerce :</h6>
                  <div className="dropdown">
                    <a
                      onClick={() => {
                        navigate("/nourish-order");
                      }}
                      className="collapse-item"
                    >
                     Nourish Orders
                    </a>
                    <a
                      onClick={() => {
                        navigate("/nourish-userlist");
                      }}
                      className="collapse-item"
                    >
                     Nourish Reg. Users
                    </a>
                  </div>  */}
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div>
                <a
                  className="nav-link collapsed"
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <AiFillFolderOpen />
                  <span style={{ marginLeft: "6px" }}>Common</span>
                </a>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Common Components:</h6>
                    <a
                      onClick={() => {
                        navigate("/header");
                      }}
                      className="collapse-item "
                    >
                      Header
                    </a>
                    <a
                      onClick={() => {
                        navigate("/footer");
                      }}
                      className="collapse-item border-0 "
                    >
                      Footer
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div>
                <a
                  className="nav-link collapsed"
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapsePages"
                  aria-expanded="true"
                  aria-controls="collapsePages"
                >
                  <AiFillFolderOpen />
                  <span style={{ marginLeft: "6px" }}>Home Page Content</span>
                </a>
                <div
                  id="collapsePages"
                  className="collapse"
                  aria-labelledby="headingPages"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Website Interface:</h6>
                    <div className="dropdown">
                      <a
                        onClick={() => {
                          navigate("/brandslider");
                        }}
                        className="collapse-item"
                      >
                        Slider
                      </a>
                      <a
                        onClick={() => {
                          navigate("/brandabout");
                        }}
                        className="collapse-item "
                      >
                        Brand About
                      </a>
                      {/* <a onClick={() => { navigate('/brandcampaignthumbnail') }} className="collapse-item ">Campaign Thumbnail</a> */}
                      <a
                        onClick={() => {
                          navigate("/brandproduct");
                        }}
                        className="collapse-item "
                      >
                        Product
                      </a>
                      <a
                        onClick={() => {
                          navigate("/philosophy");
                        }}
                        className="collapse-item "
                      >
                        {" "}
                        Philosophy
                      </a>
                      <a
                        onClick={() => {
                          navigate("/aboutoil");
                        }}
                        className="collapse-item "
                      >
                        Behind the Bailkholu
                      </a>
                      <a
                        onClick={() => {
                          navigate("/story");
                        }}
                        className="collapse-item "
                      >
                        Story
                      </a>
                      <a
                        onClick={() => {
                          navigate("/brandmedia");
                        }}
                        className="collapse-item "
                      >
                        {" "}
                        Media
                      </a>
                      <a
                        onClick={() => {
                          navigate("/blog");
                        }}
                        className="collapse-item "
                      >
                        Blogs
                      </a>
                      <a
                        onClick={() => {
                          navigate("/tvc");
                        }}
                        className="collapse-item "
                      >
                        TVC
                      </a>
                      <a
                        onClick={() => {
                          navigate("/contact");
                        }}
                        className="collapse-item "
                      >
                        Contact
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div>
                <a
                  className="nav-link collapsed"
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapsePagesHome"
                  aria-expanded="true"
                  aria-controls="collapsePagesHome"
                >
                  <AiFillFolderOpen />
                  <span style={{ marginLeft: "6px" }}>Contact Forms</span>
                </a>
                <div
                  id="collapsePagesHome"
                  className="collapse"
                  aria-labelledby="headingPages"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Website Interface:</h6>
                    <div className="dropdown">
                      <a
                        onClick={() => {
                          navigate("/freecomic");
                        }}
                        className="collapse-item"
                      >
                        Get free comic
                      </a>
                      <a
                        onClick={() => {
                          navigate("/contactform");
                        }}
                        className="collapse-item "
                      >
                        Contact With Us Form
                      </a>
                      <a
                        onClick={() => {
                          navigate("/jointhejourney");
                        }}
                        className="collapse-item "
                      >
                        Join The Journey
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div>
                <a
                  className="nav-link collapsed"
                  href="#"
                  data-toggle="collapse"
                  data-target="#ComiccollapsePagesHome"
                  aria-expanded="true"
                  aria-controls="ComiccollapsePagesHome"
                >
                  <AiFillFolderOpen />
                  <span style={{ marginLeft: "6px" }}>Comic Website Forms</span>
                </a>
                <div
                  id="ComiccollapsePagesHome"
                  className="collapse"
                  aria-labelledby="headingPages"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    {/* <h6 className="collapse-header">:</h6> */}
                    <div className="dropdown">
                      <a
                        onClick={() => {
                          navigate("/comicwriterdata");
                        }}
                        className="collapse-item "
                      >
                        Comic Writer Reg. Data
                      </a>
                      <a
                        onClick={() => {
                          navigate("/school-lib-Reg-data");
                        }}
                        className="collapse-item "
                      >
                        School/Lib Reg. Data
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div>
                <a
                  onClick={() => {
                    navigate("/campaign");
                  }}
                  className="nav-link collapsed"
                  href="#"
                  data-toggle="collapse"
                >
                  <AiFillFolderOpen />
                  <span style={{ marginLeft: "6px" }}>Campaign Page</span>
                </a>
              </div>
            </li>
            {/* {userDetail?.storeManager ? ( */}

            {/* ) : (
          ""
        )} */}
          </>
        ) : (
          <>
            {/* <li className="nav-item active">
              <a href="/" className="nav-link">
                <span style={{ marginLeft: "6px" }}>Logout Dashboard</span>
              </a>
            </li> */}
          </>
        )}
        {cleanedUserRole === "Super_Admin" ? (
          <>
            <li className="nav-item">
              <div>
                <a
                  className="nav-link collapsed"
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapsePagesHome"
                  aria-expanded="true"
                  aria-controls="collapsePagesHome"
                >
                  <AiFillFolderOpen />
                  <span style={{ marginLeft: "6px" }}>Contact Forms</span>
                </a>
                <div
                  id="collapsePagesHome"
                  className="collapse"
                  aria-labelledby="headingPages"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Website Interface:</h6>
                    <div className="dropdown">
                      <a
                        onClick={() => {
                          navigate("/freecomic");
                        }}
                        className="collapse-item"
                      >
                        Get free comic
                      </a>
                      <a
                        onClick={() => {
                          navigate("/contactform");
                        }}
                        className="collapse-item "
                      >
                        Contact With Us Form
                      </a>
                      <a
                        onClick={() => {
                          navigate("/jointhejourney");
                        }}
                        className="collapse-item "
                      >
                        Join The Journey
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div>
                <a
                  className="nav-link collapsed"
                  href="#"
                  data-toggle="collapse"
                  data-target="#ComiccollapsePagesHome"
                  aria-expanded="true"
                  aria-controls="ComiccollapsePagesHome"
                >
                  <AiFillFolderOpen />
                  <span style={{ marginLeft: "6px" }}>Comic Website Forms</span>
                </a>
                <div
                  id="ComiccollapsePagesHome"
                  className="collapse"
                  aria-labelledby="headingPages"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    {/* <h6 className="collapse-header">:</h6> */}
                    <div className="dropdown">
                      <a
                        onClick={() => {
                          navigate("/comicwriterdata");
                        }}
                        className="collapse-item "
                      >
                        Comic Writer Reg. Data
                      </a>
                      <a
                        onClick={() => {
                          navigate("/school-lib-Reg-data");
                        }}
                        className="collapse-item "
                      >
                        School/Lib Reg. Data
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </>
        ) : null}
        <hr className="sidebar-divider" />
      </ul>
    </>
  );
}
export default Slider;
