import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const NourishAppConfig = () => {
  const nourishmedusatoken = JSON.parse(localStorage.getItem("NStoken"));
  const navigate = useNavigate();
  const [config, setConfig] = useState({
    config: {
      enable_web_view: false,
      app_version: "1",
      update_type: {
        force_update: false,
        recommendation_to_update: false,
        skip: false,
        ignore: false,
      },
    },
  });

  const fetchConfig = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_Nourish_URL}store/app/config`,
        {
          headers: {
            Authorization: `Bearer ${nourishmedusatoken}`,
          },
        }
      );
      setConfig(response.data); // Directly sets the response data to config state
    } catch (error) {
      console.error("Error fetching config:", error);
    }
  };

  const saveConfig = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/app/config`,
        JSON.stringify(config), // Stringify config when sending the request
        {
          headers: {
            Authorization: `Bearer ${nourishmedusatoken}`,
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      );
      // alert("Config saved successfully");
    } catch (error) {
      console.error("Error saving config:", error);
      if (!error?.status || error?.status === "FETCH_ERROR") {
        navigate("/nslogin");
        swal({
          title: "Session Expired Please Login Again",
          icon: "error",
          dangerMode: true,
        });
      } else if (error.status === 401) {
        navigate("/nslogin");
        // swal({
        //   title: err.data.message,
        //   icon: "error",
        //   dangerMode: true,
        // });
      } else {
        swal({
          title: "Someting went wrong1",
          icon: "error",
          dangerMode: true,
        });
      }
    }
  };


  useEffect(() => {
    fetchConfig();
  }, []);

  const handleEnableWebViewToggle = () => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      config: {
        ...prevConfig.config,
        enable_web_view: !prevConfig.config.enable_web_view,
      },
    }));
  };
  const handleUpdateTypeToggle = (type) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      config: {
        ...prevConfig.config,
        update_type: {
          force_update: type === "force_update",
          recommendation_to_update: type === "recommendation_to_update",
          skip: type === "skip",
          ignore: type === "ignore",
        },
      },
    }));
  };

  return (
    <Container className="my-4">
      <Row className="justify-content-center">
        <Col lg={6} md={8}>
          <Card>
            <Card.Header as="h3" className="text-center">
              Nourish App Config
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group
                  controlId="formEnableWebView"
                  className="mb-4 d-flex"
                >
                  <Form.Check
                    className="outline-none mt-0"
                    type="switch"
                    label="Enable In-App Browser"
                    checked={config.config.enable_web_view}
                    onChange={handleEnableWebViewToggle}
                  />
                  <Form.Label className="mt-0 ml-2 text-gray-600 small">
                    Note* - App Crash Scenarios Only
                  </Form.Label>
                </Form.Group>

                <Form.Group controlId="formAppVersion" className="mb-4">
                  <Form.Label>App Version</Form.Label>
                  <Form.Control
                    type="text"
                    value={config.config.app_version} // Bind the value to state
                    onChange={(e) =>
                      setConfig((prevConfig) => ({
                        ...prevConfig,
                        config: {
                          ...prevConfig.config,
                          app_version: e.target.value, // Update app_version in state
                        },
                      }))
                    }
                  />
                </Form.Group>

                <h5>Update Type</h5>
                <Row>
                  <Col>
                    <Form.Check
                      type="radio"
                      label="Force Update"
                      name="updateType"
                      checked={config.config.update_type.force_update}
                      onChange={() => handleUpdateTypeToggle("force_update")}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="radio"
                      label="Recommendation to Update"
                      name="updateType"
                      checked={
                        config.config.update_type.recommendation_to_update
                      }
                      onChange={() =>
                        handleUpdateTypeToggle("recommendation_to_update")
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <Form.Check
                      type="radio"
                      label="Skip"
                      name="updateType"
                      checked={config.config.update_type.skip}
                      onChange={() => handleUpdateTypeToggle("skip")}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="radio"
                      label="Ignore"
                      name="updateType"
                      checked={config.config.update_type.ignore}
                      onChange={() => handleUpdateTypeToggle("ignore")}
                    />
                  </Col>
                </Row>
                <div className="text-center mt-4">
                  <Button variant="primary" onClick={saveConfig}>
                    Save Config
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NourishAppConfig;
